import React, { useEffect, useState } from "react";
import { StandartButton } from "commons/form-controls";
import { useNavigate } from "react-router-dom";

const TopicButton = (props) => {

    const navigate = useNavigate();

    const getPageName = (pageButtons, linkName) => {
        let pageName = pageButtons.filter(k => k.link === '/page/'+linkName)
        return pageName[0] && pageName[0].base_name ? props.getValue(pageName[0].base_name) : linkName
    }

    const getTopicsCountWord = (value, words) =>{  
        value = Math.abs(value) % 100; 
        var num = value % 10;
        if(value > 10 && value < 20) return words[2]; 
        if(num > 1 && num < 5) return words[1];
        if(num == 1) return words[0]; 
        return words[2];
    }

    const SetMenuSelected = (menuSelected) =>{
        props.setSelectedMenu(menuSelected)
    }

    const checkLimitTitle = (title, limit = null) => {
        if(limit && title && title.length > limit){
            let t = title.slice(0, limit);
            let str = t.split(" ");
            str.pop();
            
            return str.join(" ")+"...";
        }
        else
            return title
    }

    const getTopicNameByUUId = (topicUUId, type = null, limit) => {
  
        let selected = props.selectedTopicsF ? 'selectedTopicsF' : 'selectedTopics'

        if(props.selectedUUId && props.pageCurrentName !== 'Comparison'){
            if(props.topicData){
                
                let topic = props.topicData.filter(f => f.uuid === topicUUId )
                
                topic = props.selectedSubUUId ? topic[0] ? topic[0].subtopics.filter(s => s.uuid === props.selectedSubUUId) : topic : topic;
                return topic && topic[0] 
                        ? type ? checkLimitTitle(topic[0][type], limit) : checkLimitTitle(topic[0].name, limit)
                        : topicUUId
            }
        }
        else if(props.selectedTopics && props.selectedSubtopics && props.selectedSubtopics.length){
            return props.getValue('SELECTED_SPACE') + props.selectedSubtopics.length + ' ' +getTopicsCountWord(props.selectedSubtopics.length, [props.getValue('SUBTOPICS_SELECTED'), props.getValue('SUBTOPICS_SELECTED_1'), props.getValue('SUBTOPICS_SELECTED_2')])
        }
        else if(props.selectedTopics || props.selectedTopicsF){
            return props.getValue('SELECTED_SPACE') + props[selected].length + ' ' +getTopicsCountWord(props.selectedTopics.length, [props.getValue('TOPICS_SELECTED'), props.getValue('TOPICS_SELECTED_1'), props.getValue('TOPICS_SELECTED_2')])
        }

    }

    const [postsCount, setPostsCount] = useState(props.postsCount);
    const [topic, setTopic] = useState(getTopicNameByUUId(props.selectedUUId, 'name', 60));
    const [fullTopic, setFullTopic] = useState(getTopicNameByUUId(props.selectedUUId, 'name'));
    const [pageButtons, setPageButtons] = useState(props.headerButtons ? Object.values(props.headerButtons).map(k => k) : []);
    const [docsCount, setDocsCount] = useState(props.selectedTopics && props.selectedTopics.length || props.selectedTopicsF && props.selectedTopicsF.length || props.selectedUUId ? props.docsCount : 0)

    useEffect(() => {
        setTopic(getTopicNameByUUId(props.selectedUUId, 'name', 60));
        setPostsCount(props.postsCount);
        setPageButtons(props.headerButtons ? Object.values(props.headerButtons).map(k => k) : []);
        setFullTopic(getTopicNameByUUId(props.selectedUUId, 'name'));
        setDocsCount(props.selectedTopics && props.selectedTopics.length || props.selectedTopicsF && props.selectedTopicsF.length || props.selectedUUId ? props.docsCount : 0)
    }, [props]);
    
    const goToPage = () => {
        let filters = props.filters

        props.setStartDate(new Date(filters.since));
        props.setEndDate(new Date(filters.till));
        props.setGrouping(props.grouping);
        delete filters.since;
        delete filters.till;
        props.setMySelectedTopicsFilter(filters)
        props.filtersChanged(true);
        props.setReturnBackFilters(null);
        props.setReturnBackPageName(null);
        props.setIsReturnedBack(true);
        //props.setReturnBackPageName(getLocationName())
        setTimeout(() => {
            props.filtersChanged(false);
            SetMenuSelected(props.menuSelected)
            navigate('/page/'+props.pageName, { replace: true })
        }, 300)

    }

    const button = props.pageName ? <StandartButton name={props.getValue("RETURN_BACK_TO")+" "+getPageName(pageButtons, props.pageName)} styles={{width: 255, justifyContent: 'space-between'}} type="button" title={props.getValue("RETURN_BACK_TO")+" "+getPageName(pageButtons, props.pageName)} fill="white" callback={goToPage}/> 
                                : <StandartButton name={fullTopic ? fullTopic : ''} width={260} styles={{width: 255, justifyContent: 'space-between'}} type="button" title={topic ? topic : ''} badge={docsCount} fill="white"/>

    return (
        <div>
            { button }
        </div>
    )
}

export default TopicButton
