import Menu from "assets/menu/menu";
import { checkErrorMessage, getErrorMessage } from "./errors";
import { ComparisonService } from "../connection/topics-comaprison";
import { StorageService } from "../connection/storage-service";
import { GeoplaceService } from "connection/geoplace-service"
import { getTranslate } from "assets/titles/title-text";

const SET_COMPARISON_DATA = "SET-COMPARISON-DATA";
const SET_IS_COMPARISON_FETCHING = "SET-IS-COMPARISON-FETCHING";
const SET_SELECTED_TOPICS = "SET-SELECTED-TOPICS";
const SET_IS_CHART_UPDATED = "SET-IS-CHART-UPDATED";
const SET_COMPARISON_LOCATION = "SET-COMPARISON-LOCATION";
const SET_COMPARISON_LOCATION_DICTIONARY = "SET-COMPARIOSN_-LOCATION-DICTIONARY";
const SET_COMPARISON_MAX = "SET-COMPARISON-MAX";

const ComparisonData = {
    menu: Menu.ComparisonPages,
    selectedTopics: [],
    isComparisonFetching: false,
    isChartUpdated: false,
    comparisonLocations: null,
    locationsDictionary: null,
    max: null,
    data: []
}

const getDateTimeFormat = (date) => {
    return date//moment(date).format('YYYY-MM-DDTHH:mm:ss')+'Z'
}

let Comparison = (state = ComparisonData, action) =>{
    switch(action.type){
        case SET_COMPARISON_DATA:
            return {...state, data: action.data}
        case SET_SELECTED_TOPICS:
            let selectedTopics = [...state.selectedTopics]
            let f = selectedTopics.findIndex(k => k.id === action.selected.id)
            if(f === -1)
                selectedTopics.push(action.selected)
            else
                selectedTopics.splice(f, 1)
            return {...state, selectedTopics: selectedTopics}
        case SET_IS_COMPARISON_FETCHING:
            return {...state, isComparisonFetching: action.isFetching}
        case SET_IS_CHART_UPDATED:
            return {...state, isChartUpdated: action.isChartUpdated}
        case SET_COMPARISON_LOCATION:
            return {...state, comparisonLocations: action.comparisonLocations}
        case SET_COMPARISON_LOCATION_DICTIONARY:
            return {...state, locationsDictionary: action.locationsDictionary}
        case SET_COMPARISON_MAX:
            let max = null
            if(action.max === true && parseInt(state.max)) max = state.max
            if(action.max === true && !parseInt(state.max)) max = 20
            else if(parseInt(action.max)) max = parseInt(action.max)
            return {...state, max: max}
        default:
            return {...state}
    }
}

export default Comparison

export const setComparisonMax = (max) => {
    return {
        type: SET_COMPARISON_MAX,
        max: max
    }
}

export const setComparisonLocationsDictionary = (locationsDictionary) => {
    return {
        type: SET_COMPARISON_LOCATION_DICTIONARY,
        locationsDictionary: locationsDictionary
    }
}

export const setComparisonLocations = (comparisonLocations) => {
    return {
        type: SET_COMPARISON_LOCATION,
        comparisonLocations: comparisonLocations
    }
}

export const setIsChartUpdated = (updated) => {
    return {
        type: SET_IS_CHART_UPDATED,
        isChartUpdated: updated
    }
}

export const setIsComparisonFetching = (isFetching) => {
    return {
        type: SET_IS_COMPARISON_FETCHING,
        isFetching: isFetching
    }
}

export const setComparisonData = (data) => {
    return {
        type: SET_COMPARISON_DATA,
        data: data
    }
}

export const setSelectedTopics = (selectedTopics) => {
    return {
        type: SET_SELECTED_TOPICS,
        selected: selectedTopics
    }
}

const getLimitData = (data) => {
    let locations = []
    data.forEach(k =>  {
        {
            k.pivots.forEach((p,j)=>{
                if(p.value !== 'null'){
                        if(locations.indexOf(p.value) === -1)
                            locations.push(p.value)
                }
            })
        }
    })
    return locations
}

export const getGeoplaceDictionary = (locationsIds) => {
  return (dispatch) => {
        dispatch(setIsComparisonFetching(true))
        GeoplaceService.getGeoplaceDictionary(locationsIds).then( data=> {
            dispatch(setComparisonLocationsDictionary(data))
        }).catch( error => {  
            dispatch(setComparisonLocationsDictionary([]))  
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })
   }
}


export const getDataType = (type, getData, setData, topicId, since, till, grouping, filters, topics, subtopics, chartType = 'column', chartNumber, chartSettings = null) => {
    
    return (dispatch) => {
        dispatch(setComparisonLocationsDictionary(null))
        if(type === 'ProfileLocations'){
            
            dispatch(setComparisonMax(true))
            if(!chartSettings.max) chartSettings.max = 20
        }
        else{
            dispatch(setComparisonMax(null))
        }

        getData(subtopics && subtopics.length ? 'subtopic' : 'topic', subtopics && subtopics.length ? subtopics : topicId, getDateTimeFormat(since), getDateTimeFormat(till), grouping, filters).then(data => {
            dispatch(setData({data: [...data], topicId: topicId, type: type, chartType: chartType, chartNumber: chartNumber, chartSettings: chartSettings, topics: topics}));
        }).catch( error => {    
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            //dispatch(isComparisonFetching(false))
            dispatch(setIsChartUpdated(true))
        })
        //dispatch(isComparisonFetching(false))
    }
}

export const getMentionsData = (setData, topicIDs, since, till, grouping, filters, topics, subtopics, chartType) => {
    let mentions = [];
    let it = 0;
    return (dispatch) => {
        if(subtopics.length){
            subtopics.forEach((subtopicUUId, i) => {
                StorageService.getTopicDocuments(topicIDs[0], 0, 0, since, till, "DAILY", filters, [subtopicUUId], null).then( data => {
                    let topic = {
                        field: "subtopics",
                        value: subtopicUUId,
                        count: data.numFoundTotal,
                        pivots: [{
                            field: "mentions",
                            value: getTranslate("COUNT"),
                            count: data.numFoundTotal,
                        }]
                    }
                    mentions.push(topic)
                    
                    if(mentions.length === subtopics.length){
                        let sort = mentions.sort((a, b) => parseInt(b.count) - parseInt(a.count));
                        
                        dispatch(setData({data: sort, topicId: topicIDs, type: 'TOPICS_COMPARISON_BY_MENTIONS', topics: topics, chartType: chartType}))
                    }
                    it++
                }).catch( error => {    
                    dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
                    //dispatch(isComparisonFetching(false))
                    dispatch(setIsChartUpdated(true))
                })
                //dispatch(isComparisonFetching(false))
                
            })
        }
        else{
            topicIDs.forEach((topicUUId, i) => {
                StorageService.getTopicDocuments(topicUUId, 0, 0, since, till, "DAILY", filters, subtopics, null).then( data => {
                    let topic = {
                        field: "topic_id",
                        value: topicUUId,
                        count: data.numFoundTotal,
                        pivots: [{
                            field: "mentions",
                            value: getTranslate("COUNT"),
                            count: data.numFoundTotal,
                        }]
                    }
                        mentions.push(topic)
    
                    if(mentions.length === topicIDs.length){
                        
                        let sort = mentions.sort((a, b) => parseInt(b.count) - parseInt(a.count));
                        dispatch(setData({data: sort, topicId: topicIDs, type: 'TOPICS_COMPARISON_BY_MENTIONS', topics: topics, chartType: chartType}))
                    }
                    it++
                }).catch( error => {    
                    dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
                    //dispatch(isComparisonFetching(false))
                    dispatch(setIsChartUpdated(true))
                })
                //dispatch(isComparisonFetching(false))
                
            })
        }

    }
}

export const getDataByType = (type, topicId, since, till, grouping, filters, topics = null, subtopics = null, max) => {
    return (dispatch) => {
        dispatch(setIsComparisonFetching(true));
        
        switch(type){
            case 'MENTIONS':
                dispatch(getMentionsData(setComparisonData, topicId, since, till, grouping, filters, topics, subtopics, 'bar', 'second',{label: 'PieValue', title: 'TOPICS_COMPARISON_BY_MENTIONS'}))
                break;
            case 'MEDIA_TYPES':
                dispatch(getDataType('media_types', ComparisonService.getComparisonMediaTypes, setComparisonData, topicId, since, till, grouping, filters, topics, subtopics, 'column', 'second',{label: 'PieValue', title: 'TOPICS_COMPARISON_BY_MEDIA_TYPE'}));
                break;
            case 'SENTIMENTS':
                dispatch(getDataType('Sentiment', ComparisonService.getComparisonSentiments, setComparisonData, topicId, since, till, grouping, filters, topics, subtopics, 'column', 'first',{label: 'languageDate', title: 'TOPICS_COMPARISON_BY_TONE', graphicType: {label: 'date', data: 'language'}}));
                break;
            case 'LANGUAGES':
                dispatch(getDataType('Languages', ComparisonService.getComparisonLanguages, setComparisonData, topicId, since, till, grouping, filters, topics, subtopics, 'column', 'second',{label: 'PieValue', title: 'TOPICS_COMPARISON_BY_LANG'}));
                break;
            case 'MEDIA_SCALES':
                dispatch(getDataType('MediaScales', ComparisonService.getComparisonMediaScales, setComparisonData, topicId, since, till, grouping, filters, topics, subtopics, 'column', 'second',{label: 'PieValue', title: 'TOPICS_COMPARISON_BY_MASS_MEDIA_STATUS'}));
                break;
            case 'PROFILE_LOCATIONS':
                dispatch(getDataType('ProfileLocations', ComparisonService.getComparisonProfileLocations, setComparisonData, topicId, since, till, grouping, filters, topics, subtopics, 'column', 'second',{label: 'PieValue', title: 'TOPICS_COMPARISON_BY_REGIONS', max: max}));
                break;
        }
    }
}
