import React, { useState, useEffect } from "react";
import { InputText } from "commons/input-controls";
import { StandartButton } from "commons/form-controls";
import {ReactComponent as SaveIcon} from "assets/icons/save.svg";
import {ReactComponent as CloseIcon} from "assets/icons/close.svg";
import {ReactComponent as OKIcon} from "assets/icons/ok.svg"
import {ReactComponent as PlusIcon} from "assets/icons/plus.svg";
import { InputCheckBox } from "commons/input-controls";

import s from "./add-tag.module.scss";

const AddTag = (props) => {
    const getSelectedTags = (type) => {
        switch(type){
            case 'list':
            case 'page':
                let n = [];
                Object.entries(props.selectedPostTags).forEach(k => {
                                                                    if(k[1] === props.body.signature.length) n.push(k[0])
                                                                });
                return n;
            case 'post':
                return props.selectedPostTags ? Object.entries(props.selectedPostTags).map(k => k[0]) : []
        }
    }

    const [newTag, setNewTag] = useState(null);
    const [newTagColor, setNewTagColors] = useState(null);
    const [newTagTextColor, setNewTagTextColors] = useState(null);
    const [checkedTags, setCheckedTags] = useState(getSelectedTags(props.body.type));
    const [tagName, setTagName] = useState(null)
    const [selectedAllList, SetselectedAllList] = useState(props.selectedAllList)
    const [pressedAdd, setPressedAdd] = useState([]);
    const [pressedRemove, setPressedRemove] = useState([]);
    const [rerender, setRerender] = useState(false);
    const [tagType, setTagType] = useState(props.body.type);

    useEffect(()=>{
        setCheckedTags(getSelectedTags(props.body.type));
        SetselectedAllList(props.selectedAllList)
        setTagType(props.body.type);
        //setRerender(!rerender)
    }, [props.selectedPostTags, props.selectedAllList, props.body.type])

    const getTagTextColor = (color) => {
        return color === '#000000' ? '#FFFFFF' : '#000000'
    }

    const goto = (e) => {
        props.addNewTag(e)
    }

    const addNewTagText = (e) => {
        setNewTag(e)
    }

    const setNewTagColor = (color, textColor) => {
        setNewTagColors(color);
        setNewTagTextColors(textColor)
    }

    const createTag = () => {
        setNewTag('')
        props.createCompanyTag(props.companyId, newTag, newTagColor, newTagTextColor);
    }

    const setPressedButton = (index, type, prop) => {
        if(type === 'add'){
            let k = pressedAdd;
            if(pressedRemove.indexOf(index) !== -1){
                let t = pressedRemove;
                t.splice(index, 1)
                setPressedRemove(t)
            }
            if(pressedAdd.indexOf(index) === -1){
                
                k.push(index)
                setPressedAdd(k)
                addTagToDocuments(...prop)
            }
        }
        else if(type === 'remove'){
            let k = pressedRemove;
            if(pressedAdd.indexOf(index) !== -1){
                let j = pressedAdd;
                j.splice(index, 1)
                setPressedAdd(j)
            }
            if(pressedRemove.indexOf(index) === -1){
                k.push(index)
                setPressedRemove(k)
                addTagToDocuments(...prop)
            }
        }
        setRerender(!rerender)
    }

    const addTagToDocuments = (signature, tagUUID, tagName, removeTag = false) => {
        let n = [...checkedTags];
        let v = [];
        let isCheckedT = selectedAllList ? removeTag : checkedTags.indexOf(tagUUID) !== -1 ? true : false;
        let filters = props.selectedMyFilter;

        if(props.searchList)
            filters.clauses = props.searchList;

        if(!isCheckedT){
            
            n.push(tagUUID);
            
            v.push(tagName)
            setCheckedTags(n)
            
            if(tagType === 'page'){
                props.setSelectedPostTags(tagUUID, true, props.body.signature.length);
                props.addTagsToListDocuments(props.body.signature, tagUUID, props.body.signature.length)
            }
            else if(tagType === 'list'){
                let feedType = props.getFeedType(props.pageName)
                props.setSelectedPostTags(tagUUID, true, props.body.signature.length);
                props.addTagsToListDocuments(props.body.signature, tagUUID, props.body.signature.length)
                props.updateTagsMultipleTopics(tagUUID, props.selectedUUId ? props.selectedUUId : props.selectedTopics, props.selectedSubUUId ? [props.selectedSubUUId] : null, feedType, props.startDate, props.endDate, props.grouping, filters)
            }
            else{
                props.addTagToDocument(signature, tagUUID);
                props.setSelectedPostTags(tagUUID, true);
            }
        }
        else{
            if(tagType === 'page'){
                props.deleteTagsFromListDocuments(props.body.signature, tagUUID)
                props.setSelectedPostTags(tagUUID, false, props.body.signature.length);
                
            }
            else if(tagType === 'list'){
                let feedType = props.getFeedType(props.pageName)
                props.deleteTagsFromListDocuments(props.body.signature, tagUUID)
                props.deleteTagsMultipleTopics(tagUUID, props.selectedUUId ? props.selectedUUId : props.selectedTopics, props.selectedSubUUId ? [props.selectedSubUUId] : null, feedType, props.startDate, props.endDate, props.grouping, props.selectedMyFilter)
                props.setSelectedPostTags(tagUUID, true, null);
            }
            else{
                
                n.splice(checkedTags.indexOf(tagUUID.toString()), 1)
                props.removeTagFromDocument(signature, tagUUID)
                setCheckedTags(n)
                setRerender(!rerender)
            }

        }
    }

    const colors = props.colors.map((c,i)=>{
        return <div 
                    onClick={setNewTagColor.bind(this, c.color, c.textColor)} 
                    className={newTagColor && newTagColor === c.color ? s.colorBlock+" "+s.activeColor : s.colorBlock} 
                    style={{background: c.color}} 
                    key={i}>
                </div>
    })

    const tags = props.tags && props.tags.map((t,i)=>{
        let isCheckedT = false;

        isCheckedT = checkedTags.indexOf(t.uuid) !== -1 ? true : false;
        if(!tagName){
            return (
                <div key={i} className={s.tagBlock}>            
                    <div className={s.checkboxBlock}>
                        {selectedAllList 
                                                ? <StandartButton disabled={pressedAdd.indexOf(i) !== -1} width={24} height={24} background={'var(--white)'} icon={pressedAdd.indexOf(i) !== -1 ? OKIcon : PlusIcon} callback={setPressedButton.bind(this, i, 'add', [props.body.signature, t.uuid, t, false])} stroke={'dark'}/> 
                                                : <InputCheckBox isChecked={isCheckedT} name={t.uuid} title={''} callback={addTagToDocuments.bind(this, props.body.signature, t.uuid, t)}/> }
                        <span key={t.id} className={s.Tag} style={{background: t.color, color: t.textColor}} onClick={addTagToDocuments.bind(this, props.body.signature, t.uuid, t)}>{t.name}</span>
                    </div>
                        {selectedAllList 
                                                ? <StandartButton disabled={pressedRemove.indexOf(i) !== -1} width={24} height={24} background={'var(--white)'} icon={CloseIcon} callback={setPressedButton.bind(this, i, 'remove', [props.body.signature, t.uuid, t, true])}/> 
                                                : null}
                </div>
            )
        }
        else{
            return (
                (tagName && t.name.toLowerCase().indexOf(tagName.toLowerCase()) >= 0) && <div key={i} className={s.tagBlock}>            
                    <div className={s.checkboxBlock}>
                        <InputCheckBox isChecked={isCheckedT} name={t.uuid} title={''} callback={addTagToDocuments.bind(this, props.body.signature, t.uuid, t)}/>
                    </div>
                    <span key={t.id} className={s.Tag} style={{background: t.color, color: t.textColor}} onClick={addTagToDocuments.bind(this, props.body.signature, t.uuid, t)}>{t.name}</span>
                </div>
            )
        }
    })

    const onTagChange = (tag) => {
        setTagName(tag)
    }

    const clearTag = () => {
        setNewTag('');
        setNewTagColor(null);
        props.addNewTag('');
        props.addNewTagColor(null)
    }



    return (
        <div className={s.AddTag}>
            <div className={s.rowName}>
                <InputText value={tagName} callback={onTagChange} type="text" placeholder={props.getValue('SEARCH_TAG')} />
            </div>
            <div className={s.existingTagsBlock}>
                {tags}
            </div>
            <hr className={s.line}></hr>
            <div className={s.createNewTagsBlock}>
                <div className={s.headerTitleText}>
                    <span className={s.title}>{props.getValue('CREATE_NEW_TAG')}</span>
                </div>
                <div className={s.rowName}>
                    <span className={s.TagsTitleText}>{props.getValue('TEXT_OF_TAG')}</span>
                </div>
                <InputText value={newTag} callback={addNewTagText} goto={goto} placeholder={props.getValue('ENTER_TEXT_OF_TAG')}/>
                <div className={s.rowName}>
                    <span className={s.TagsTitleText}>{props.getValue('FILL_COLOR')}</span>
                </div>
                <div className={s.colorsBlock}>
                    {colors}
                </div>
                { props.newTag && <div>
                    <div className={s.rowName}>
                        <span className={s.TagsTitleText}>{props.getValue('TAG_PREVIEW')}</span>
                    </div>
                        <div className={s.newTagPreview}>
                            <span className={s.Tag} style={{background: props.newTagColor, color: getTagTextColor(props.newTagColor)}}>{props.newTag}</span>
                        </div>
                    </div>
                }
            </div>
            <div className={s.ButtonsBlock}>
                {props.newTag}
                <StandartButton callback={createTag} disabled={(!newTag || !newTagColor)} title={props.getValue('SAVE')} icon={SaveIcon}/>
                <StandartButton callback={clearTag} disabled={!newTag && !newTagColor} title={props.getValue('CANCEL')} icon={CloseIcon} stroke={'white'}/>
            </div>

        </div>
    )
}

export default AddTag;